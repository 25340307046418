<template>
  <div class="content-sav">
    <div class="font-sz-16 font-text bold-700 label-sav">
      {{ $i18n.locale === 'fr' ? 'Détails' : 'Details' }}
    </div>
    <v-row class="mt-3">
      <v-col>
        <v-text-field
          label="Client"
          dense
          :persistent-placeholder="true"
          outlined
          color="#5C2DD3"
          item-color="#5C2DD3"
        >
        </v-text-field>
        <v-autocomplete
          :clearable="true"
          color="#5C2DD3"
          multiple
          :placeholder="$t('selectionner')"
          item-color="#5C2DD3"
          :items="[]"
          :persistent-placeholder="true"
          chips
          return-object
          :deletable-chips="true"
          :small-chips="true"
          label="Type"
          item-text="text"
          dense
          item-value="id"
          :no-data-text="$t('noDataOptionMsg', { option: 'type' })"
          outlined
          :menu-props="{
            bottom: true,
            offsetY: true
          }"
        >
        </v-autocomplete>
        <v-autocomplete
          :clearable="true"
          color="#5C2DD3"
          multiple
          :placeholder="$t('selectionner')"
          item-color="#5C2DD3"
          :items="[]"
          :persistent-placeholder="true"
          chips
          return-object
          :deletable-chips="true"
          :small-chips="true"
          :label="$i18n.locale === 'fr' ? 'Motif' : 'Pattern'"
          item-text="text"
          dense
          item-value="id"
          :no-data-text="
            $t('noDataOptionMsg', {
              option: $i18n.locale === 'fr' ? 'Motif' : 'Pattern'
            })
          "
          outlined
          :menu-props="{
            bottom: true,
            offsetY: true
          }"
        >
        </v-autocomplete>
        <v-autocomplete
          :clearable="true"
          color="#5C2DD3"
          multiple
          :placeholder="$t('selectionner')"
          item-color="#5C2DD3"
          :items="[]"
          :persistent-placeholder="true"
          chips
          return-object
          :deletable-chips="true"
          :small-chips="true"
          :label="$i18n.locale === 'fr' ? 'Attribué a' : 'Attributed to'"
          item-text="text"
          dense
          item-value="id"
          :no-data-text="$t('noDataOption')"
          outlined
          :menu-props="{
            bottom: true,
            offsetY: true
          }"
        >
        </v-autocomplete>
        <v-autocomplete
          :clearable="true"
          color="#5C2DD3"
          multiple
          :placeholder="$t('selectionner')"
          item-color="#5C2DD3"
          :items="[]"
          :persistent-placeholder="true"
          chips
          return-object
          :deletable-chips="true"
          :small-chips="true"
          :label="$t('statut')"
          item-text="text"
          dense
          item-value="id"
          :no-data-text="$t('noDataOptionMsg', { option: $t('statut') })"
          outlined
          :menu-props="{
            bottom: true,
            offsetY: true
          }"
        >
        </v-autocomplete>
        <v-row>
          <v-col>
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="$i18n.locale == 'fr' ? 'Créé le' : 'Created on'"
                  outlined
                  readonly
                  clearable
                  dense
                  v-on="on"
                  v-bind="attrs"
                  :persistent-placeholder="true"
                  :placeholder="$t('msgSelectDate')"
                  prepend-inner-icon="mdi-calendar"
                  color="#5C2DD3"
                ></v-text-field>
              </template>

              <v-date-picker
                no-title
                locale="fr"
                color="#5C2DD3"
                header-color="#5C2DD3"
                event-color="704ad1"
              >
              </v-date-picker> </v-menu
          ></v-col>
          <v-col>
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="$i18n.locale == 'fr' ? 'Réglé le' : 'Set on'"
                  outlined
                  readonly
                  clearable
                  dense
                  v-on="on"
                  v-bind="attrs"
                  :persistent-placeholder="true"
                  :placeholder="$t('msgSelectDate')"
                  prepend-inner-icon="mdi-calendar"
                  color="#5C2DD3"
                ></v-text-field>
              </template>

              <v-date-picker
                no-title
                locale="fr"
                color="#5C2DD3"
                header-color="#5C2DD3"
                event-color="704ad1"
              >
              </v-date-picker> </v-menu
          ></v-col>
        </v-row>
        <div class="switch-cloture">
          <v-switch
            :label="$i18n.locale == 'fr' ? 'Clôturée' : 'Enclosed'"
            color="#5c2dd3c9"
            class="switch-bottom label-switch ml-3 mb-2"
            hide-details
          >
          </v-switch>
        </div>
      </v-col>
      <v-col>
        <v-textarea
          class="custom-textarea"
          dense
          :label="
            $i18n.locale == 'fr' ? 'Problèmes constatés' : 'Problems identified'
          "
          v-model="description_probleme"
          :persistent-placeholder="true"
          rows="5"
          outlined
          color="#5C2DD3"
        ></v-textarea>
        <v-textarea
          class="custom-textarea"
          dense
          :label="$i18n.locale == 'fr' ? 'Observations' : 'Observations'"
          v-model="description_observations"
          :persistent-placeholder="true"
          rows="5"
          outlined
          color="#5C2DD3"
        ></v-textarea>
      </v-col>
    </v-row>
    <div class="block-note mt-3">
      <v-row class="mt-2">
        <v-col cols="6">
          <div class="font-sz-16 font-text bold-700 label-sav padding-bottom">
            {{ $i18n.locale == 'fr' ? 'Commentaires' : 'Comments' }}
          </div>

          <v-textarea
            class="custom-textarea"
            dense
            :label="
              $i18n.locale == 'fr' ? 'Ajouter un commentaire' : 'Add a comment'
            "
            v-model="description_comment"
            :persistent-placeholder="true"
            rows="3"
            outlined
            color="#5C2DD3"
          ></v-textarea>
        </v-col>
        <v-col></v-col> <v-col></v-col>
      </v-row>
    </div>
    <v-row class="mt-3">
      <v-col cols="5"></v-col>
      <v-col cols="7">
        <div class="float-right">
          <v-btn color="#5C2DD3" outlined dark class="mr-2">
            {{ $i18n.locale == 'fr' ? 'voir le dossier' : 'see the folder' }}
            <v-icon class="ml-1">mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn color="#5C2DD3" dark outlined class="mr-2">
            {{ $i18n.locale == 'fr' ? 'fiche sav' : 'file sav' }}
            <v-icon class="ml-1">mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn color="#5C2DD3" dark>
            {{ $i18n.locale == 'fr' ? 'enregistrer' : 'save' }}
            <v-icon class="ml-1">mdi-tray-arrow-down</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      switchActive: true,
      description_comment: '',
      description_observations: '',
      description_probleme: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.content-sav {
  padding: 28px 28px;
  position: relative;
  max-height: calc(100vh - 206px) !important;
  height: calc(100vh - 206px) !important;
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: auto;
  cursor: pointer;
  width: 100%;
  .label-sav {
    letter-spacing: 0.15px;
    line-height: 19.5px;
  }
  .padding-bottom {
    padding-bottom: 20px !important;
  }
  .switch-cloture {
    background-color: #f6f6f6;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    height: 60px;
    width: 100%;
    top: 672px;
    left: 353px;
    .v-label {
      font-size: 16px !important;
      font-family: 'Roboto' sans-serif !important;
      line-height: 24px !important;
      font-weight: 400 !important;
      letter-spacing: 0.15px !important;
      color: #3a167f !important;
    }
  }
  .block-note {
    width: 100%;
    height: 227px;
    top: 775px;
    left: 357px;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
    background-color: #f6f6f6;
    padding: 0px 15px;
  }
}
.content-sav::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.content-sav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 4px;
  background-color: rgba(238, 238, 238, 1) !important;
}
.content-sav::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: black;
}
</style>
<style lang="scss">
.content-sav {
  .switch-cloture {
    .v-label {
      font-size: 16px !important;
      font-family: 'Roboto' sans-serif !important;
      line-height: 24px !important;
      font-weight: 400 !important;
      letter-spacing: 0.15px !important;
      color: #3a167f !important;
    }
    .v-input--switch__thumb {
      color: #fff !important;
    }
  }
  .custom-textarea ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .custom-textarea ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }

  .custom-textarea ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #bdbdbd;
  }
}
</style>
