<template>
  <div class="page-global-sav">
    <div class="header-page-sav">
      <div class="title-header">
        {{
          $i18n.locale === 'fr'
            ? `Nom du type de ${selectedTitle}`
            : `Project type name ${selectedTitle}`
        }}
      </div>
      <div>
        <div class="flex-center">
          <template
            v-if="selectedTitle == 'projet' || selectedTitle == 'project'"
          >
            <div class="choisi-actions mr-2 color-gris">
              <v-menu
                offset-y
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ attrs, on }">
                  <div v-bind="attrs" v-on="on">
                    <span>
                      <font-awesome-icon
                        :icon="['fal', 'circle-info']"
                        class="img-h-19 margin-svg color-crm-bold"
                      />
                    </span>
                    <span
                      class="font-sz-14 font-text bold-700 mr-3 ml-2 label-btn"
                      >{{
                        $i18n.locale === 'fr' ? 'Fiche SAV' : 'File SAV'
                      }}</span
                    >
                    <v-progress-circular
                      v-if="false"
                      indeterminate
                      color="#5C2DD3"
                      class="sz-loader-icon"
                    ></v-progress-circular>
                    <!-- <v-icon class="icon-flech ml-3" v-else
                        >mdi-chevron-down</v-icon
                      > -->
                  </div>
                </template>
              </v-menu>
            </div>

            <div class="choisi-actions mr-2 color-gris">
              <v-menu
                offset-y
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ attrs, on }">
                  <div v-bind="attrs" v-on="on">
                    <span>
                      <font-awesome-icon
                        class="img-h-19 margin-svg color-crm-bold"
                        :icon="['far', 'camera']"
                      />
                    </span>
                    <span
                      class="font-sz-14 label-btn font-text bold-700 mr-3 ml-2"
                    >
                      {{
                        $i18n.locale === 'fr'
                          ? 'Ajouter des photos'
                          : 'Add photos'
                      }}
                    </span>
                    <v-progress-circular
                      v-if="false"
                      indeterminate
                      color="#5C2DD3"
                      class="sz-loader-icon"
                    ></v-progress-circular>
                    <!-- <v-icon class="icon-flech ml-3" v-else
                        >mdi-chevron-down</v-icon
                      > -->
                  </div>
                </template>
              </v-menu>
            </div>

            <div class="choisi-actions mr-2 color-gris">
              <v-menu
                offset-y
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ attrs, on }">
                  <div v-bind="attrs" v-on="on">
                    <span>
                      <font-awesome-icon
                        class="img-h-19 margin-svg color-crm-bold"
                        :icon="['fal', 'image']"
                      />
                    </span>
                    <span
                      class="font-sz-14 font-text bold-700 mr-3 ml-2 label-btn"
                    >
                      {{ $i18n.locale === 'fr' ? 'Photos' : 'Photos' }}</span
                    >
                    <v-progress-circular
                      v-if="false"
                      indeterminate
                      color="#5C2DD3"
                      class="sz-loader-icon"
                    ></v-progress-circular>
                    <!-- <v-icon class="icon-flech ml-3" v-else
                        >mdi-chevron-down</v-icon
                      > -->
                  </div>
                </template>
              </v-menu>
            </div>
          </template>
          <div class="choisi-actions">
            <v-menu
              offset-y
              v-model="openAction"
              origin="center center"
              transition="scale-transition"
              :nudge-bottom="8"
              :nudge-left="0"
              :nudge-width="15"
            >
              <template v-slot:activator="{ attrs, on }">
                <div v-bind="attrs" v-on="on" class="flex-center">
                  <span>
                    <font-awesome-icon
                      class="img-h-14"
                      :icon="['far', 'computer-mouse-scrollwheel']"
                    />
                  </span>
                  <span
                    class="font-sz-14 label-btn font-text bold-700 mr-3 ml-2"
                  >
                    {{
                      $i18n.locale === 'fr'
                        ? 'Choisir une action'
                        : 'choose an action'
                    }}</span
                  >
                  <v-progress-circular
                    v-if="false"
                    indeterminate
                    color="#5C2DD3"
                    class="sz-loader-icon"
                  ></v-progress-circular>
                  <v-icon class="icon-flech ml-3" v-else
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </template>
              <v-list class="list-type-projet-scroll">
                <v-list-item class="list-type-projet-item">
                  <v-list-item-title
                    class=" list-type-onglet-projet block-flex-align-space-between"
                  >
                    <span
                      class="text-center mr-2 font-text font-sz-12 bold-700"
                    >
                      {{
                        $i18n.locale === 'fr' ? 'Aucune Action' : 'No Action'
                      }}</span
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedTitle: this.$i18n.locale === 'fr' ? 'projet' : 'project',
      selectedPage: 'sav',
      openAction: false
    }
  },
  watch: {
    $route(route) {
      if (route && route.name == 'sav') {
        this.selectedTitle = this.$i18n.locale === 'fr' ? 'projet' : 'project'
        this.selectedPage = 'sav'
      } else {
        this.selectedTitle = 'sav'
        this.selectedPage = 'service_client'
      }
    }
  },
  mounted() {
    if (this.$route && this.$route.name == 'sav') {
      this.selectedTitle = this.$i18n.locale === 'fr' ? 'projet' : 'project'
      this.selectedPage = 'sav'
    } else {
      this.selectedTitle = 'sav'
      this.selectedPage = 'service_client'
    }
  }
}
</script>
<style lang="scss" scoped>
.page-global-sav {
  .header-page-sav {
    margin: 13px 12px 13px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .title-header {
      font-size: 20px;
      font-weight: 700;
      font-family: 'Montserrat' sans-serif;
      letter-spacing: 1%;
      line-height: 24.38px;
    }
    .choisi-actions {
      border-radius: 3px;
      background-color: transparent;
      padding: 5px 7px 5px 7px;
      border: 1px solid #e5e5e5;
      color: #2f2f2f;
      white-space: nowrap;
      cursor: pointer;
      .label-btn {
        letter-spacing: 1%;
        line-height: 17.07px;
      }
      &.color-gris {
        background-color: #f6f6f6;
      }
      .margin-svg {
        margin-bottom: -3px;
      }
    }
  }
}
</style>
